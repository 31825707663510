import moment from 'moment';
import { PAYMENT_DUE_DAYS_BEFORE_TRIP_START } from '../business.constants';
import { DATE_MONTHS } from '../app.constants';
import { models } from '@trova-trip/trova-models';
import {
    getDaysInMonth,
    isValid,
    parseISO,
    addMonths,
    addDays,
    addHours,
    addMinutes,
} from 'date-fns';
import {
    getTimezoneOffset,
    formatInTimeZone,
    zonedTimeToUtc,
    toDate,
} from 'date-fns-tz';
import {
    hoursToMilliseconds,
    differenceInYears,
    differenceInDays,
} from 'date-fns';
import { TimeZoneName, timezones } from './timezones';

type Trip = models.trips.BaseTrip;

// example: 2023-10-20T09:59:33.164Z
const ISO_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

// example: 2023-10-20T09:59:33Z
const ISO_DATE_EXTENDED_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

// example: 2023-10-20T09:59:33.000+10:00
const ISO_DATE_TIMEZONE_OFFSET_FORMAT =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/;

interface getStartEndDatesProps {
    (startDate: string, length: number, format: string): [string, string];
}

/**
 * Create 0 padded string from the given number.
 * @param num - number to pad, '7' => '07'
 * @param amount - amount of digits to pad, 3 => '007'
 * @returns
 */
const pad = (num: number, amount: number) => ('' + num).padStart(amount, '0');

const extractYearMonthDay = (dateObj: Date | string): number[] => {
    const yearMonthDay =
        typeof dateObj === 'string'
            ? dateObj
                  .split('T')[0]
                  .split('-')
                  .map((t, idx) => (idx === 1 ? Number(t) - 1 : Number(t)))
            : [dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()];

    return yearMonthDay;
};

export const getZeroUTCResetDateObj = (dateObj: Date | string): Date => {
    const [year, month, day] = extractYearMonthDay(dateObj);

    const correctDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    return correctDate;
};

export const getZeroLocalResetDateObj = (dateObj: Date | string): Date => {
    const [year, month, day] = extractYearMonthDay(dateObj);

    const correctDate = new Date(year, month, day);
    return correctDate;
};

export const asISOZeroTimeString = (dateObj: Date | string): string =>
    getZeroUTCResetDateObj(dateObj).toISOString(); //Returns Date String: formatted as YYYY-MM-DDTHH:MM:SSZ

export const getStartEndDates: getStartEndDatesProps = (
    startDate,
    length,
    format,
) => {
    const formattedEndDate = moment(startDate)
        .utc()
        .add(length - 1, `days`)
        .format(format);
    const formattedStartDate = moment(startDate).utc().format(format);
    return [formattedStartDate, formattedEndDate];
}; //Returns a Date String Array: [startDate, endDate] formatted according to the third parameter

/**
 * Formats a pair of start/end dates to follow the "month day, year" syntax.
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {{ tz: TimeZones }} config config object to set formatting options as Timezone.
 * @returns {string} */
export const formatDateRangeToLong = (
    startDate: Date,
    endDate: Date,
    config?: { tz: TimeZoneName },
): string => {
    const formattedDates = {
        start: formatDateToLong(startDate, config?.tz),
        end: formatDateToLong(endDate, config?.tz),
    };
    return `${formattedDates.start} - ${formattedDates.end}`;
};

export const getCalendarMonthNamesMap = (): Record<string, string> => {
    return DATE_MONTHS.reduce((accum: { [key: string]: string }, current) => {
        accum[current] = current;
        return accum;
    }, {});
};

export type DateComponents = {
    /**
     * Day of the month.
     */
    day: number;
    /**
     * 1-12 month of the year.
     */
    month: number;
    /**
     * 4-digit year.
     */
    year: number;
    /**
     * 0-23 hour of the day.
     */
    hours?: number;
    /**
     * 0-59 minute of the hour.
     */
    minutes?: number;
    /**
     * 0-59 second of the minute.
     */
    seconds?: number;
    /**
     * 0-999 milliseconds of the second.
     */
    milliseconds?: number;
};

/**
 * Create a Date from the given components adjusted by the timezone offset.
 * The created Date is in UTC.
 * @param {DateComponents} hours - the components of the date before applying the timezone offset.
 * @param {number|undefined} timeZoneOffsetMinutes - Timezone offset in minutes
 * @returns {Date}
 */
export const createUTCDate = (
    dateValues: DateComponents,
    timeZoneOffsetMinutes?: number,
): Date => {
    const dayString = pad(dateValues.day, 2);
    const monthString = pad(dateValues.month, 2);
    const yearString = `${dateValues.year}`;
    const hoursString = pad(dateValues.hours ?? 0, 2);
    const minutesString = pad(dateValues.minutes ?? 0, 2);
    const secondsString = pad(dateValues.seconds ?? 0, 2);
    const millisecondsString = pad(dateValues.milliseconds ?? 0, 3);

    const dateString = `${yearString}-${monthString}-${dayString}`;
    const timeString = `${hoursString}:${minutesString}:${secondsString}.${millisecondsString}`;

    const timeZoneOffset = timeZoneOffsetMinutes ?? 0;

    const utcDate = new Date(`${dateString}T${timeString}Z`);
    const localizedTime = utcDate.getTime() + timeZoneOffset * 60 * 1000;

    const localizedDate = new Date(localizedTime);

    if (isNaN(localizedDate.getTime())) {
        throw new Error(`Invalid date: ${dateString}T${timeString}Z`);
    }

    return localizedDate;
};

/**
 * Convert the given date from the user's local timezone to UTC.
 * @param {DateComponents} dateValues - the components of the date in the users local timezone.
 * @returns
 */
export const localToUtc = (dateValues: DateComponents) => {
    const tzDate = new Date();
    return createUTCDate(dateValues, tzDate.getTimezoneOffset());
};

export const createTripEndDate = (
    startDate: string | Date,
    tripLength: number,
): Date => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + tripLength - 1);
    return date;
};

/**
 * Returns an array of dates between the start and end dates
 * (start and end dates are included)
 * This method is intended to work with 'Simple Dates' midnight UTC.
 * @param {string | Date} start start date
 * @param {string | Date} end end date
 * @returns {Date[]} an array of dates between the start and end dates
 */
export const eachDayBetweenDates = (
    start: string | Date,
    end: string | Date,
): Date[] => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const intervalLength = getDifferenceInDays(startDate, endDate);
    const dates = [];
    for (let i = 0; i <= intervalLength; i++) {
        dates.push(addDays(startDate, i));
    }
    return dates
};

/**
 * Returns an array of dates for each day of the trip
 * (start and end dates are included)
 * This method is intended to work with 'Simple Dates' at midnight UTC.
 * @param {string | Date} startDate start date
 * @param {number} tripLength length of the trip
 * @returns {Date[]} an array of dates for each day of the trip
 */
export const eachDayOfTrip = (
    startDate: string | Date,
    tripLength: number,
): Date[] => {
    const start = new Date(startDate);
    const dates = [];
    for (let i = 0; i < tripLength; i++) {
        dates.push(addDays(start, i));
    }
    return dates;
};

export function createPaymentDueDate(trip: Trip): Date {
    if (!trip.startDate) {
        throw new Error(
            `Trip ${trip._id} is missing start date, cannot set booking due date`,
        );
    }
    const paymentDueDate: Date = new Date(trip.startDate);
    paymentDueDate.setDate(
        trip.startDate.getDate() - PAYMENT_DUE_DAYS_BEFORE_TRIP_START,
    );
    return paymentDueDate;
}

export const getDaysDiffFromCurrentDate = (date: Date): number => {
    return moment.utc(date).diff(moment.utc(), 'days');
};

/**
 * Returns the local time zone in IANA format
 *
 * @returns {string} The local time zone in IANA format
 */
const getSystemTimeZone = (): string => {
    const tzIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return tzIANA;
};

/**
 * Formats a given date in the specified time zone and format string
 *
 * @param {Date} date The date to be formatted
 * @param {string} formatString The format string to be used for formatting the
 *   date
 * @param {string=} timeZone (Optional) The time zone to be used for formatting
 *   the date. If not specified, the local time zone is used. Offsets and IANA
 *   both supported. Ex: (+08:00, +08, Z, Z+8, PST)
 * @returns {string} The formatted date string
 */
export const formatDateTime = (
    date: Date,
    formatString: string,
    timeZone: string = getSystemTimeZone(),
): string => {
    return formatInTimeZone(date, timeZone, formatString);
};

/**
 * Formats a given date in 24-hour time (HH:mm) in the specified time zone
 *
 * @param {Date} date The date to be formatted
 * @param {string=} timeZone (Optional) The time zone to be used for formatting
 *   the date. If not specified, the local time zone is used. Offsets and IANA
 *   both supported. Ex: (+08:00, +08, Z, Z+8)
 * @returns {string} The formatted date string in the format "HH:mm"
 */
export const formatTime24Short = (
    date: Date,
    timeZone: string = getSystemTimeZone(),
): string => {
    const dateFormat = 'HH:mm';
    return formatDateTime(date, dateFormat, timeZone);
};

/**
 * Formats a given date in the US Style short format "MM/dd/yyyy" in the specified time
 * zone
 *
 * @param {Date} date The date to be formatted
 * @param {string=} timeZone (Optional) The time zone to be used for formatting
 *   the date. If not specified, the local time zone is used. Offsets and IANA
 *   both supported. Ex: (+08:00, +08, Z, Z+8)
 * @returns {string} The formatted date string in the format "MM/dd/yyyy"
 */
export const formatDateUSShort = (
    date: Date,
    timeZone: string = getSystemTimeZone(),
): string => {
    const dateFormat = 'MM/dd/yyyy';
    return formatDateTime(date, dateFormat, timeZone);
};

/**
 * Formats a given date in the US Style format "MM/dd/yyyy HH:mm:ss" in the specified time
 * zone
 *
 * @param {Date} date The date to be formatted
 * @param {string=} timeZone (Optional) The time zone to be used for formatting
 *   the date. If not specified, the local time zone is used. Offsets and IANA
 *   both supported. Ex: (+08:00, +08, Z, Z+8)
 * @returns {string} The formatted date string in the format "MM/dd/yyyy HH:mm:ss"
 */
export const formatDateUSFull = (
    date: Date,
    timeZone: string = getSystemTimeZone(),
): string => {
    const dateFormat = 'MM/dd/yyyy HH:mm:ss';
    return formatDateTime(date, dateFormat, timeZone);
};

/**
 * Formats a date to follow the "month day, year" syntax.
 * @param {Date} date
 * @param {string=} timeZone (Optional) The time zone to be used for formatting
 *   the date. If not specified, the local time zone is used. Offsets and IANA
 *   both supported. Ex: (+08:00, +08, Z, Z+8, PST)
 * @returns {string} */
export const formatDateToLong = (
    date: Date,
    timeZone: string = getSystemTimeZone(),
): string => {
    const dateFormat = 'MMM dd, yyyy';
    return formatDateTime(date, dateFormat, timeZone);
};

/**
 * Converts a U.S. short date string ('10/31/2022') to date time string format ('2022-10-31').
 * The output format is specified here {@link https://tc39.es/ecma262/multipage/numbers-and-dates.html#sec-date-time-string-format}
 * and also matches the hyphenated ISO 8601 date format.
 *
 * @param usShortDate The date in 'mm/dd/yyyy' format - e.g. '02/05/1995'
 * @returns The date in date time string format - e.g. '1995-02-05'
 * @throws An error if the input format is invalid.
 */
export const convertUsShortDateToIsoDateString = (
    usShortDate: string,
): string => {
    const monthDayYearWithSlashes =
        /^(0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;
    if (!monthDayYearWithSlashes.test(usShortDate)) {
        throw new Error(
            'Invalid input format - must be mm/dd/yyyy like "01/23/2023".',
        );
    }

    const [month, day, year] = usShortDate.split('/');
    return `${year}-${month}-${day}`;
};

/**
 * Converts a date or date string to a UTC Date object.
 *
 * Prefer `createDateForTimeZone` if you
 * have a specific time zone and can provide the date time string format (e.g. '2022-10-14').
 *
 * @param date The date or date string to convert.
 * @param timeZone The IANA time zone name to use
 *   for the conversion. Defaults to the system time zone.
 * @returns The converted UTC Date object.
 */
export const parseLocalDateTimeToUtc = (
    date: Date | string,
    timeZone: string = getSystemTimeZone(),
): Date => {
    return zonedTimeToUtc(date, timeZone);
};

/**
 * Create a Date object using a date/time string and a time zone.
 *
 * @param timeZone The IANA time zone name to use
 * @param dateString The date/time in the given time zone
 * Must be in valid date time string format *without* an offset or an hour value of '24'.
 * The format is specified here: {@link https://tc39.es/ecma262/multipage/numbers-and-dates.html#sec-date-time-string-format}
 * @returns A new Date with a UTC time value that corresponds to the given date/time in the given time zone.
 * @throws An Invalid Date error if the date string is invalid.
 *
 * @example
 * // Create a Date that represents Halloween 2022 at 8pm in Pacific time
 * createDateForTimeZone('America/Los_Angeles', '2022-10-31T20')
 */
export const createDateForTimeZone = (
    timeZone: TimeZoneName,
    dateString: string,
): Date => toDate(dateString, { timeZone: timeZone });

/**
 * @name dropTime
 * Create a new date by dropping the time (hours, minutes, seconds and milliseconds)
 * to zero for the given date and timezone.
 * @param date - the date to be changed.
 * @param {string} [timeZone=getSystemTime()] The IANA time zone name to use
 *   for the conversion. Defaults to the system time zone.
 *
 * @example
 * Input:
 * 1673316000000 = 2023-01-09 18:00:00 PST
 * Returns:
 * 1673251200000 = 2023-01-09 00:00:00 PST
 */
export const dropTime = (date: Date, timeZone?: string): Date => {
    const tz = timeZone ? timeZone : 'UTC';
    const unixTime = date.getTime();
    const timeZoneOffsetMilliseconds = getTimezoneOffset(tz, date);
    const millisecondsInDay = hoursToMilliseconds(24);

    const midnightOffset =
        (unixTime + timeZoneOffsetMilliseconds) % millisecondsInDay;

    const midnightInTargetTimeZone = unixTime - midnightOffset;
    return new Date(midnightInTargetTimeZone);
};

/**
 * @name timezoneNames
 * A list of IANA timezone names.
 * @returns string[]
 */
export const timezoneNames = (): typeof timezones => {
    return timezones;
};

type DateProperty =
    | 'shortYear'
    | 'fullYear'
    | 'monthName'
    | 'shortMonthName'
    | 'monthNumber'
    | 'dayName'
    | 'shortDayName'
    | 'dayNumber'
    | 'daysInMonth';

export type GetDatePropertyConfig = {
    tz: TimeZoneName;
};

/**
 * @name getDateProperty
 * Returns a selected date property such as the month name
 * with the possibility to specify the timezone.
 *
 * @param {Date} date
 * @param {DateProperty} prop
 * @param {{tz: TimeZoneName}} config
 * @returns string
 */
export const getDateProperty = (
    date: Date,
    prop: DateProperty,
    config?: GetDatePropertyConfig,
): string => {
    const formats: Record<Exclude<DateProperty, 'daysInMonth'>, string> = {
        shortYear: 'yy',
        fullYear: 'yyyy',
        monthName: 'MMMM',
        shortMonthName: 'MMM',
        monthNumber: 'MM',
        dayName: 'EEEE',
        shortDayName: 'EEE',
        dayNumber: 'dd',
    };
    if (prop === 'daysInMonth') {
        return getDaysInMonth(date).toString();
    }
    return formatDateTime(date, formats[prop], config?.tz);
};

export const getAge = (dateOfBirth: Date): number =>
    differenceInYears(new Date(), dateOfBirth);

/**
 * Validate an ISO date string. Accepted format is ISO 8601 (Date and time) YYYY-MM-DDTHH:MM:SS.MSZ
 * @param isoDateString
 * @example
 * isIsoDateValid('2023-10-20T09:59:33.164Z')
 */
export const isIsoDateValid = (isoDateString: string): boolean => {
    if (
        typeof isoDateString === 'string' &&
        ISO_DATE_FORMAT.test(isoDateString)
    ) {
        const dateObj = parseISO(isoDateString);
        return isValid(dateObj);
    }
    return false;
};

/**
 * Adds or subtracts a specified number of days from a given date.
 * @param date Date to provide
 * @param daysToAddOrSubtract Amount of days to add or subtract.
 * Ex: -1 for the previous day, 1 for the next day.
 */
export const addDaysToDate = (
    date: Date,
    daysToAddOrSubtract: number,
): Date => {
    return addDays(date, daysToAddOrSubtract);
};

/**
 * Adds or subtracts a specified number of hours from a given date.
 * @param date Date to provide
 * @param hoursToAddOrSubtract Amount of hours to add or subtract.
 * Ex: -1 for the previous hour, 1 for the next one.
 */
export const addHoursToDate = (
    date: Date,
    hoursToAddOrSubtract: number,
): Date => {
    return addHours(date, hoursToAddOrSubtract);
};

/**
 * Adds or subtracts a specified number of minutes from a given date.
 * @param date Date to provide
 * @param minutesToAddOrSubtract Amount of minutes to add or subtract
 * Ex: -1 for the previous minute, 1 for the next one.
 */
export const addMinutesToDate = (
    date: Date,
    minutesToAddOrSubtract: number,
): Date => {
    return addMinutes(date, minutesToAddOrSubtract);
};

/**
 * Adds or subtracts a specified number of months from a given date.
 * @param date Date to provide
 * @param monthsToAddOrSubtract Amount of months to add or subtract.
 * Ex: -1 for the previous month, 1 for the next month.
 */
export const addMonthsToDate = (
    date: Date,
    monthsToAddOrSubtract: number,
): Date => {
    return addMonths(date, monthsToAddOrSubtract);
};

/**
 * Returns the difference in days between two dates.
 * @param earlierDate The earlier date
 * @param laterDate The later date
 */
export const getDifferenceInDays = (
    earlierDate: Date,
    laterDate: Date,
): number => {
    return differenceInDays(laterDate, earlierDate);
};

/**
 * Returns the current date in UTC.
 */
export const getCurrentDateInUTC = (): Date => {
    return parseLocalDateTimeToUtc(new Date());
};

/**
 * Reformat a string date in UTC to a new format.
 *
 * @param dateString - must be a valid date string in ISO 8601 format, that has date, time and
 * UTC offset. For example, `2024-08-19T00:00:00.000+00:00` or `2024-08-19T10:30:00Z`
 * @param newFormat - any accepted date-fns format string. https://date-fns.org/v1.29.0/docs/format
 *
 * @returns the reformatted date string in UTC
 * @throws an error if the input format is invalid, or a date-fns `RangeError` if the format is invalid.
 *
 * @example
 * const dateString = '2024-08-19T00:00:00.000+00:00';
 * const newFormat = 'yyyy-MM-dd';
 * reformatUtcDateString(dateString, newFormat); // '2024-08-19'
 */
export const reformatUtcDateString = (
    dateString: string,
    newFormat: string,
): string => {
    const isValidInputFormat =
        ISO_DATE_FORMAT.test(dateString) ||
        ISO_DATE_EXTENDED_FORMAT.test(dateString) ||
        ISO_DATE_TIMEZONE_OFFSET_FORMAT.test(dateString);

    if (!isValidInputFormat) {
        throw new Error(
            'Invalid input format. Date string must be in ISO 8601 format with date, time, and a UTC offset.',
        );
    }

    const dateObject = new Date(dateString);

    return formatDateTime(dateObject, newFormat, 'UTC');
};
