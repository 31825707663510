import Big from 'big.js';

type NumberSource = number | string | BigDecimal;

/**
 * Class utility to handle decimal arithmetic.
 */
export class BigDecimal {
    #value: Big;

    private constructor(initialValue: NumberSource | Big) {
        if (initialValue instanceof BigDecimal) {
            this.#value = initialValue.#value;
        } else if (initialValue instanceof Big) {
            this.#value = initialValue;
        } else {
            this.#value = new Big(initialValue);
        }
    }

    /**
     * Returns a new BigDecimal number instance for the given NumberSource.
     *
     * @throws `NaN` on an invalid value.
     */
    static of(initialValue: NumberSource) {
        return new BigDecimal(initialValue);
    }

    /**
     * Returns a BigDecimal number whose value is the value of this BigDecimal number divided by n.
     *
     * If the result has more fraction digits than is specified by Big.DP, it will be rounded to Big.DP decimal places using rounding mode Big.RM.
     *
     * @throws `NaN` if n is invalid.
     * @throws `±Infinity` on division by zero.
     * @throws `NaN` on division of zero by zero.
     */
    div(num: NumberSource): BigDecimal {
        if (num instanceof BigDecimal) {
            return new BigDecimal(this.#value.div(num.#value));
        }
        return new BigDecimal(this.#value.div(num));
    }

    /**
     * Returns a BigDecimal number whose value is the value of this number minus n.
     *
     * @throws `NaN` if n is invalid.
     */
    minus(num: NumberSource): BigDecimal {
        if (num instanceof BigDecimal) {
            return new BigDecimal(this.#value.minus(num.#value));
        }
        return new BigDecimal(this.#value.minus(num));
    }

    /**
     * Returns a BigDecimal whose value is the value of this number plus n.
     *
     * @throws `NaN` if n is invalid.
     */
    plus(num: NumberSource): BigDecimal {
        if (num instanceof BigDecimal) {
            return new BigDecimal(this.#value.plus(num.#value));
        }
        return new BigDecimal(this.#value.plus(num));
    }

    /**
     * Returns a BigDecimal number whose value is the value of this BigDecimal number times n.
     *
     * @throws `NaN` if n is invalid.
     */
    times(num: NumberSource): BigDecimal {
        if (num instanceof BigDecimal) {
            return new BigDecimal(this.#value.times(num.#value));
        }
        return new BigDecimal(this.#value.times(num));
    }

    /**
     * Returns a string representing the value of this Trova number in normal notation to a fixed number of dp decimal places.
     */
    toFixed(dp?: number): string {
        return this.#value.toFixed(dp);
    }

    /**
     * Returns a primitive number representing the value of this Trova number.
     */
    toNumber(): number {
        return this.#value.toNumber();
    }

    /**
     * Returns a string representing the value of this Trova number.
     */
    toString(): string {
        return this.#value.toString();
    }
}
